import { API } from '@/constants/api'
import { METHOD, frontmanZendeskApi, request } from '@/utils/api'

import { TicketField } from './types'

export type GetTicketFieldResponse = {
  ticket_field: TicketField
}

export type GetTicketFieldOption = {
  isProxy?: boolean
}

export const getTicketField = (name: string, option: GetTicketFieldOption = {}) => {
  return frontmanZendeskApi<GetTicketFieldResponse>({
    path: `api/zendesk/ticket/field/${name}`,
    method: METHOD.GET,
    ...option,
  })
}
