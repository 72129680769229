import { css } from '@emotion/react'
import styled from '@emotion/styled'
import {
  ChangeEvent,
  FocusEvent,
  HTMLInputTypeAttribute,
  InputHTMLAttributes,
  KeyboardEvent,
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'

import { COLOR } from '@/styles/color'
import { fontSize, textHidden } from '@/styles/mixin'

import SearchButton from './SearchButton'
import IcoCancelSolid from './images/ico_cancel_solid.svg?url'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  clear?: boolean
  onClear?: () => void
  onSearch?: () => void
  // TODO: 에러 메세지 처리
  error?: boolean | string | string[]
}

const Input = forwardRef<HTMLInputElement, InputProps>(
  (
    {
      className,
      type = 'text',
      value: externalValue,
      clear = false,
      maxLength,
      error,
      required,
      onChange,
      onKeyDown,
      onFocus,
      onBlur,
      onClear,
      onSearch,
      ...rest
    },
    ref
  ) => {
    const inputRef = useRef<HTMLInputElement | null>(null)
    const [value, setValue] = useState('')
    const [isFocus, setIsFocus] = useState(false)
    const [visibleClearButton, setVisibleClearButton] = useState(false)
    const [visibleSearchButton, setVisibleSearchButton] = useState(false)

    const handleChange = useCallback(
      (e: ChangeEvent<HTMLInputElement>) => {
        const { value: targetValue } = e.target
        if (maxLength && targetValue.length > maxLength) {
          // 길이가 maxLength를 넘어가면 이후 입력을 무시한다.
          e.preventDefault()
          return
        }
        setValue(targetValue)
        onChange?.(e)
      },
      [onChange, maxLength]
    )

    const handleKeyDown = useCallback(
      (e: KeyboardEvent<HTMLInputElement>) => {
        if (type === 'search' && e.key === 'Enter' && !!value.length) {
          e.preventDefault()
          onSearch?.()
        }
        onKeyDown?.(e)
      },
      [type, value, onSearch, onKeyDown]
    )

    const handleVisibleClearButton = useCallback(() => {
      if (clear) {
        setVisibleClearButton(isFocus && !!value.length)
      }
    }, [clear, isFocus, value])

    const handleFocus = useCallback(
      (e: FocusEvent<HTMLInputElement, Element>) => {
        setIsFocus(true)
        handleVisibleClearButton()
        onFocus?.(e)
      },
      [onFocus, handleVisibleClearButton]
    )

    const handleBlur = useCallback(
      (e: FocusEvent<HTMLInputElement, Element>) => {
        setIsFocus(false)
        handleVisibleClearButton()
        onBlur?.(e)
      },
      [onBlur, handleVisibleClearButton]
    )

    const handleClear = useCallback(() => {
      inputRef.current?.focus()
      setValue('')
      onClear?.()
    }, [onClear])

    const handleSearch = useCallback(() => {
      onSearch?.()
    }, [onSearch])

    const currentLength = useMemo(() => value.length, [value])
    const maxLengthError = useMemo(
      () => !!maxLength && value.length > maxLength,
      [maxLength, value]
    )
    const isError = useMemo(() => {
      if (maxLengthError) {
        return true
      }

      return !!error
    }, [maxLengthError, error])

    // Value 값이 변경(또는 input type이 변경) 되었을때
    useEffect(() => {
      handleVisibleClearButton()
      setVisibleSearchButton(type === 'search' && !!value.length)
    }, [value, type, handleVisibleClearButton])

    // 초기 값 & 외부 값이 변경되었을 때
    useEffect(() => {
      setValue((externalValue ?? '') as string)
    }, [externalValue])

    return (
      <Container
        className={className}
        type={type}
        error={isError}
      >
        <InputEl
          ref={(e) => {
            if (ref) {
              if (typeof ref === 'function') {
                ref(e)
              } else {
                ref.current = e
              }
            }
            inputRef.current = e
          }}
          value={value}
          maxLength={maxLength}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          onFocus={handleFocus}
          onBlur={handleBlur}
          {...rest}
        />
        <Extra>
          {visibleClearButton && <ClearButton onClick={handleClear}>삭제</ClearButton>}
          {visibleSearchButton && <SearchButton onClick={handleSearch} />}
          {maxLength && (
            <Length error={maxLengthError}>
              <em>{currentLength}</em>/{maxLength}
            </Length>
          )}
        </Extra>
      </Container>
    )
  }
)

Input.displayName = 'Input'

export const Container = styled.div<{ type?: HTMLInputTypeAttribute; error?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  width: 100%;
  background-color: ${COLOR.gray.color.wb[0]};
  border-width: 1px;
  border-style: solid;

  & > * + * {
    margin-left: 12px;
  }

  ${({ type }) => {
    switch (type) {
      case 'search':
        return css`
          height: 40px;
          border-radius: 425px;
          border-color: ${COLOR.gray.color.gray[600]};
        `
      default:
        return css`
          height: 48px;
          border-radius: 4px;
          border-color: ${COLOR.gray.color.gray[300]};
        `
    }
  }}

  ${({ error }) =>
    error &&
    css`
      border-color: ${COLOR.semantic.color.red[500]};
    `}
`

const InputEl = styled.input`
  flex: 1;
  display: block;
  ${({ theme }) => fontSize(theme, 14)}
  background-color: inherit;
  color: ${COLOR.gray.color.gray[900]};

  &::placeholder {
    color: ${COLOR.gray.color.gray[400]};
  }
`

export const Extra = styled.div`
  display: flex;
  align-items: center;

  & > * + * {
    margin-left: 12px;
  }
`

const ClearButton = styled.button`
  width: 22px;
  height: 22px;
  background: url(${IcoCancelSolid}) no-repeat center center;
  ${textHidden()}
`

const Length = styled.div<{ error: boolean }>`
  ${({ theme }) => fontSize(theme, 11)}
  color: ${COLOR.gray.color.gray[500]};

  ${({ error }) =>
    error &&
    css`
      em {
        color: ${COLOR.semantic.color.red[500]};
      }
    `}
`

export default memo(Input)
